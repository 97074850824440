<template>
  <header>
    <div class="container">
      <div class="flex justify-between">
        <div class="flex">
          <div class="logo">
            <router-link :to="{ name: 'ww.dashboard' }">
              <img src="@/assets/img/logo-full.svg" alt="Logo">
            </router-link>
          </div>
          <div class="md:hidden">
            <div class="ml-10 flex">
              <router-link :to="{ name: 'ww.dashboard' }">
                <span class="text-gray-400 font-medium">Wilms World</span>
              </router-link>
              <router-link :to="breadcrumb[1]" v-for="(breadcrumb, index) in breadcrumbs" :key="breadcrumb[0]" class="flex">
                <Icon class="mx-2 text-gray-400" icon="fa:angle-right"/>
                <span :class="[index != breadcrumbs.length - 1 ? 'text-gray-400 font-medium' : 'text-wilmsBlue font-bold']">{{ breadcrumb[0] }}</span>
              </router-link>
            </div>
          </div>
        </div>

        <div class="right">
          <div class="flex">
            <div class="pr-5 border-r-default border-gray-200 flex justify-center gap-4">
              <button class="">
                <img class="mx-auto h-6 text-wilmsBlue" alt="" src="@/assets/img/iot/np_notification.svg"/>
                <span class="text-wilmsBlue font-bold text-xs">{{ $t('Notifications') }}</span>
              </button>
              <LanguageSwitcher class="mr-4"/>
            </div>
            <div class="menu pl-5" v-if="currentUser">
              <div class="button" v-click-outside="closeUserMenu" @click="openUserMenu()">
                <img width="38" alt="User avatar" src="~@/assets/img/user.svg"/>
                <span class="text-wilmsBlue font-bold text-base">{{ currentUser.profile ? currentUser.profile.first_name : currentUser.email }}</span>
                <Icon class="ml-2 mt-1" icon="fa:angle-down"/>
              </div>
              <div class="dropdown" :class="{'active': menuOpen}">
                <router-link :to="{ name: 'ww.logout' }">{{ $t('Sign out') }}</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import LanguageSwitcher from '@/components/iot/LanguageSwitcher'
import { Icon } from '@iconify/vue2'

export default {
  components: {
    LanguageSwitcher,
    Icon
  },
  props: ['breadcrumbs'],
  data () {
    return {
      menuOpen: false
    }
  },
  methods: {
    openUserMenu () {
      this.menuOpen = true
    },
    closeUserMenu () {
      this.menuOpen = false
    }
  },
  computed: {
    currentUser () {
      return this.$store.getters.getCurrentUser
    }
  }
}
</script>
