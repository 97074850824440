<template>
  <footer>
    <div class="container">
      <div class="flex">
        <div class="logo">
          <img src="@/assets/img/logo-wilms-2.svg" alt="Logo" width="89" height="94">
        </div>

        <div class="info">
          <h2>Wilms NV</h2>
          <p>Molsebaan 20</p>
          <p>B-2450 Meerhout</p>
        </div>

        <div class="info">
          <h2>Contacteer ons</h2>
          <p>+32(0)14 36 99 70</p>
          <p>info@wilms.be</p>
        </div>

        <div class="info">
          <h2>Volg ons</h2>

          <div class="socials">
            <a href="https://www.facebook.com/Wilmsnv/" target="_blank" title="Facebook"><i class="fab fa-facebook-f"></i></a>
            <a href="https://www.linkedin.com/company/wilms-nv/" target="_blank" title="LinkedIn"><i class="fab fa-linkedin-in"></i></a>
            <a href="https://nl.pinterest.com/wilmsnv/" target="_blank" title="Pinterest"><i class="fab fa-pinterest"></i></a>
            <a href="https://www.youtube.com/channel/UCTuOTgyCrUMJdfFAb7BJ7-w" target="_blank" title="Youtube"><i class="fab fa-youtube"></i></a>
          </div>
        </div>

        <div class="stamp">
          <img src="@/assets/img/stempel-wilms.svg" alt="Patended Technology - Wilms" width="95" height="95">
        </div>

        <div class="sig">
          <img src="@/assets/img/signatuur-inv-nl.svg" width="243" height="16">
        </div>

        <div class="links">
          <p>© Wilms</p>
          <a href="https://wilms.be/nl/ons-werkgebied">Ons werkgebied</a>
          <a href="https://wilms.b/assets/main/WILMS_disclaimer_202101NL.pdf">Privacyverklaring</a>
          <a href="https://wilms.b/assets/main/WILMS_cookiepolicy_202101NL.pdf">Cookie policy</a>
          <a>BE 0422.115.690</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
}
</script>
