<template>
  <div>
    <Header :breadcrumbs="breadcrumbs"/>

    <main class="content">
      <transition name="fade" mode="out-in">
        <router-view :key="$route.fullPath" @changeHeader="changeHeader"></router-view>
      </transition>
    </main>

    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/iot/Header'
import Footer from '@/components/iot/Footer'

export default {
  name: 'Layout',
  data () {
    return {
      breadcrumbs: null
    }
  },
  components: {
    Header,
    Footer
  },
  mounted () {
    document.body.classList.remove('admin')
    document.body.classList.add('ui')
  },
  methods: {
    changeHeader (values) {
      this.breadcrumbs = values
    }
  }
}
</script>
